import React from 'react';

import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';

import { Row, View } from './components';
import { FAQ, Features, Footer, Hero, Pricing } from './components/organisms';
import { ReactComponent as Logo } from './logo.svg';

export const App = () => {
    mixpanel.track('Visit');
    return (
        <>
            <Page>
                <Row justifyContent={['center', 'flex-start']}>
                    <Logo width={200} height={100} />
                </Row>
                <View mt={0}>
                    <Hero />
                </View>

                <View mb={50}>
                    <Features />
                </View>

                <View mb={50}>
                    <FAQ />
                </View>

                <View mt={10}>
                    <Pricing />
                </View>
            </Page>
            <View>
                <Footer />
            </View>
        </>
    );
};

const Page = styled.div`
    background-color: #101010;
    padding: 20px;
`;
