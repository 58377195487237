import React from 'react';

const features = [
    {
        name: 'Runnable in 30 seconds',
        description:
            'With a simple command your database will be up and running in 30 seconds. That is all you need to do. The rest is on us.',
    },
    {
        name: 'Scalable up and down',
        description:
            'Your database can scale up and down automatically or manually depending on your preference.',
    },
    {
        name: 'Completely managed',
        description:
            'Never worry about backups, availability and maintenance again. All our DB instances are completely managed by us.',
    },
];

export const Features = () => {
    return (
        <div className="py-24">
            <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-4">
                <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
                    {features.map((feature) => (
                        <div key={feature.name}>
                            <dt>
                                <p className="mt-5 text-4xl leading-7 font-extrabold text-lime-500">
                                    {feature.name}
                                </p>
                            </dt>
                            <dd className="mt-4 text-base text-gray-100">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    );
};
