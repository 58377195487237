import React from 'react';

import { CodeSnippet } from '../CodeSnippet';

const faqs = [
    {
        question: 'Create your account.',
        answer: 'strukture register',
    },
    {
        question: 'Authenticate',
        answer: 'strukture login',
    },
    {
        question: 'Create your database',
        answer: 'strukture databases create',
    },
    {
        question: 'List your databases',
        answer: 'strukture databases list',
    },
    {
        question: 'More commands coming up',
        answer: '...',
    },
];

export const FAQ = () => {
    return (
        <div className="py-18 md:py-24">
            <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-4">
                <h2 className="text-3xl font-extrabold text-white md:text-center">
                    List of commands
                </h2>
                <div className="mt-12">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
                        {faqs.map((faq) => (
                            <div key={faq.question}>
                                <dt className="text-lg leading-6 font-medium text-gray-400">
                                    {faq.question}
                                </dt>
                                <dd className="mt-1">
                                    <CodeSnippet>{faq.answer}</CodeSnippet>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
};
