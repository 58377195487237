import React from 'react';

import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom/client';
import './reset.css';
import './index.css';
import { ThemeProvider } from 'styled-components';

import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { Theme } from './theme';

LogRocket.init('wxr3zi/cloudstrukt');
mixpanel.init('11a8adff93df986fd87262799975b4e1');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={Theme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
