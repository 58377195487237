import React from 'react';

import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { PopupButton } from '@typeform/embed-react';

const plans = [
    {
        title: 'Tryouts',
        featured: false,
        description: 'Always free. No credit card required.',
        priceMonthly: 'FREE',
        priceLabel: '',
        note: 'Always free',
        mainFeatures: [
            { id: 1, value: '1 running database' },
            { id: 2, value: 'Basic network settings' },
            { id: 3, value: '1GB of storage' },
            { id: 4, value: 'Tier 1 instances' },
        ],
    },
    {
        title: 'Starter',
        featured: true,
        description: 'Unlimited databases with extendable storage',
        priceMonthly: '$21',
        priceLabel: 'Starting price',
        note: 'per month',
        mainFeatures: [
            { id: 1, value: 'Unlimited databases' },
            { id: 2, value: 'Advanced network settings' },
            { id: 3, value: '30GB of storage (extendable)' },
            { id: 4, value: 'Daily backups' },
            { id: 5, value: 'Tier 2 instances' },
            { id: 6, value: 'Tunnel using SSH' },
        ],
    },
    {
        title: 'Rolling',
        featured: false,
        description: 'Convenient features to take your development to the next level.',
        priceMonthly: 'Contact',
        priceLabel: '',
        note: '',
        mainFeatures: [
            { id: 1, value: 'Unlimited databases' },
            { id: 2, value: 'Custom storage configuration' },
            { id: 3, value: 'Custom security settings' },
            { id: 4, value: 'Custom Tier instances' },
        ],
    },
];
const features = [
    {
        title: 'CLI runs',
        tiers: [
            { title: 'tryouts', value: true },
            { title: 'starter', featured: true, value: true },
            { title: 'rolling', value: true },
        ],
    },
    {
        title: 'Networking',
        tiers: [
            { title: 'tryouts', value: 'Basic' },
            { title: 'starter', featured: true, value: 'Advanced' },
            { title: 'rolling', value: 'Custom' },
        ],
    },
    {
        title: 'Storage',
        tiers: [
            { title: 'tryouts', value: '1 GB' },
            { title: 'popular', featured: true, value: '30 GB (+ $0.5 per GB)' },
            { title: 'rolling', value: 'Custom' },
        ],
    },
    {
        title: 'Instance types',
        tiers: [
            { title: 'tryouts', value: 'Tier 1 (vCPU: 2 Memory: 1 GiB)' },
            { title: 'starter', featured: true, value: 'Tier 2 (vCPU: 2 Memory: 4 GiB)' },
            { title: 'rolling', value: 'Custom' },
        ],
    },
    {
        title: 'Backups',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: 'Daily' },
            { title: 'rolling', value: 'Custom' },
        ],
    },
    {
        title: 'Backup retention',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: 'up to 14 days' },
            { title: 'rolling', value: 'Custom' },
        ],
    },
    {
        title: 'IOPS provisioning',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: false },
            { title: 'rolling', value: true },
        ],
    },
];
const perks = [
    {
        title: 'Email Customer support',
        tiers: [
            { title: 'tryouts', value: true },
            { title: 'starter', featured: true, value: true },
            { title: 'rolling', value: true },
        ],
    },
    {
        title: 'Phone Customer support',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: true },
            { title: 'rolling', value: true },
        ],
    },
    {
        title: 'Dedicated Customer support',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: false },
            { title: 'rolling', value: true },
        ],
    },
    {
        title: 'Dedicated Slack Channel',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: false },
            { title: 'rolling', value: true },
        ],
    },
    {
        title: 'Custom requests',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: false },
            { title: 'rolling', value: true },
        ],
    },
];

const databases = [
    {
        title: 'Postgres',
        tiers: [
            { title: 'tryouts', value: true },
            { title: 'starter', featured: true, value: true },
            { title: 'rolling', value: true },
        ],
    },
    {
        title: 'MySQL',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: true },
            { title: 'rolling', value: true },
        ],
    },
    {
        title: 'Redis',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: false },
            { title: 'rolling', value: true },
        ],
    },
    {
        title: 'Mongo',
        tiers: [
            { title: 'tryouts', value: false },
            { title: 'starter', featured: true, value: false },
            { title: 'rolling', value: true },
        ],
    },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export const Pricing = () => {
    return (
        <div className="bg-gray-50 rounded rounded-xl overflow-hidden">
            <div className="relative bg-lime-600">
                {/* Overlapping background */}
                <div
                    aria-hidden="true"
                    className="hidden absolute bg-gray-50 w-full h-6 bottom-0 lg:block"
                />

                <div className="relative max-w-2xl mx-auto pt-16 px-4 text-center sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-6xl">
                        <span className="block lg:inline">Simple pricing, </span>
                        <span className="block lg:inline">no commitment.</span>
                    </h1>
                    <p className="mt-4 text-xl text-lime-100">
                        Everything you need, nothing you don't. Pick a plan that best suits your
                        development.
                    </p>
                </div>

                <h2 className="sr-only">Plans</h2>

                {/* Toggle */}
                {/*<div className="relative mt-12 flex justify-center sm:mt-16">*/}
                {/*    <div className="bg-lime-700 p-0.5 rounded-lg flex">*/}
                {/*        <button*/}
                {/*            type="button"*/}
                {/*            className="relative bg-white py-2 px-6 border-lime-700 rounded-md shadow-sm text-sm font-medium text-lime-700 whitespace-nowrap hover:bg-lime-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-lime-700 focus:ring-white focus:z-10"*/}
                {/*        >*/}
                {/*            Monthly billing*/}
                {/*        </button>*/}
                {/*        <button*/}
                {/*            type="button"*/}
                {/*            className="ml-0.5 relative py-2 px-6 border border-transparent rounded-md text-sm font-medium text-lime-200 whitespace-nowrap hover:bg-lime-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-lime-700 focus:ring-white focus:z-10"*/}
                {/*        >*/}
                {/*            Yearly billing*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* Cards */}
                <div className="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0">
                    {/* Decorative background */}
                    <div
                        aria-hidden="true"
                        className="hidden absolute top-4 bottom-6 left-8 right-8 inset-0 bg-lime-700 rounded-tl-lg rounded-tr-lg lg:block"
                    />

                    <div className="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3">
                        {plans.map((plan) => (
                            <div
                                key={plan.title}
                                className={classNames(
                                    plan.featured
                                        ? 'bg-white ring-2 ring-lime-700 shadow-md'
                                        : 'bg-lime-700 lg:bg-transparent',
                                    'pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12',
                                )}
                            >
                                <div>
                                    <h3
                                        className={classNames(
                                            plan.featured ? 'text-lime-600' : 'text-white',
                                            'text-sm font-semibold uppercase tracking-wide',
                                        )}
                                    >
                                        {plan.title}
                                    </h3>
                                    <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                                        <div className="mt-3 flex items-center">
                                            <p
                                                className={classNames(
                                                    plan.featured ? 'text-lime-600' : 'text-white',
                                                    'text-4xl font-extrabold tracking-tight',
                                                )}
                                            >
                                                {plan.priceMonthly}
                                            </p>
                                            <div className="ml-4">
                                                <p
                                                    className={classNames(
                                                        plan.featured
                                                            ? 'text-gray-700'
                                                            : 'text-white',
                                                        'text-sm',
                                                    )}
                                                >
                                                    {plan.priceLabel}
                                                </p>
                                                <p
                                                    className={classNames(
                                                        plan.featured
                                                            ? 'text-gray-500'
                                                            : 'text-lime-200',
                                                        'text-sm',
                                                    )}
                                                >
                                                    {plan.note}
                                                </p>
                                            </div>
                                        </div>
                                        <a
                                            href="#"
                                            className={classNames(
                                                plan.featured
                                                    ? 'bg-lime-600 text-white hover:bg-lime-700'
                                                    : 'bg-white text-lime-600 hover:bg-lime-50',
                                                'mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full',
                                            )}
                                        >
                                            <PopupButton id="GdyNrWaY" style={{ fontSize: 20 }}>
                                                <p className={'text-sm'}>Select {plan.title}</p>
                                            </PopupButton>
                                        </a>
                                    </div>
                                </div>
                                <h4 className="sr-only">Features</h4>
                                <ul
                                    role="list"
                                    className={classNames(
                                        plan.featured
                                            ? 'border-gray-200 divide-gray-200'
                                            : 'border-lime-500 divide-lime-500 divide-opacity-75',
                                        'mt-7 border-t divide-y lg:border-t-0',
                                    )}
                                >
                                    {plan.mainFeatures.map((mainFeature) => (
                                        <li key={mainFeature.id} className="py-3 flex items-center">
                                            <CheckIcon
                                                className={classNames(
                                                    plan.featured
                                                        ? 'text-lime-500'
                                                        : 'text-lime-200',
                                                    'w-5 h-5 flex-shrink-0',
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span
                                                className={classNames(
                                                    plan.featured ? 'text-gray-600' : 'text-white',
                                                    'ml-3 text-sm font-medium',
                                                )}
                                            >
                                                {mainFeature.value}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Feature comparison (up to lg) */}
            <section aria-labelledby="mobile-comparison-heading" className="lg:hidden">
                <h2 id="mobile-comparison-heading" className="sr-only">
                    Feature comparison
                </h2>

                <div className="max-w-2xl mx-auto py-16 px-4 space-y-16 sm:px-6">
                    {plans.map((plan, mobilePlanIndex) => (
                        <div key={plan.title} className="border-t border-gray-200">
                            <div
                                className={classNames(
                                    plan.featured ? 'border-lime-600' : 'border-transparent',
                                    '-mt-px pt-6 border-t-2 sm:w-1/2',
                                )}
                            >
                                <h3
                                    className={classNames(
                                        plan.featured ? 'text-lime-600' : 'text-gray-900',
                                        'text-sm font-bold',
                                    )}
                                >
                                    {plan.title}
                                </h3>
                                <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                            </div>
                            <h4 className="mt-10 text-sm font-bold text-gray-900">Plan features</h4>

                            <div className="mt-6 relative">
                                {/* Fake card background */}
                                <div
                                    aria-hidden="true"
                                    className="hidden absolute inset-0 pointer-events-none sm:block"
                                >
                                    <div
                                        className={classNames(
                                            plan.featured ? 'shadow-md' : 'shadow',
                                            'absolute right-0 w-1/2 h-full bg-white rounded-lg',
                                        )}
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        plan.featured
                                            ? 'ring-2 ring-lime-600 shadow-md'
                                            : 'ring-1 ring-black ring-opacity-5 shadow',
                                        'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none',
                                    )}
                                >
                                    <dl className="divide-y divide-gray-200">
                                        {features.map((feature) => (
                                            <div
                                                key={feature.title}
                                                className="py-3 flex items-center justify-between sm:grid sm:grid-cols-2"
                                            >
                                                <dt className="pr-4 text-sm font-medium text-gray-600">
                                                    {feature.title}
                                                </dt>
                                                <dd className="flex items-center justify-end sm:px-4 sm:justify-center">
                                                    {typeof feature.tiers[mobilePlanIndex].value ===
                                                    'string' ? (
                                                        <span
                                                            className={classNames(
                                                                feature.tiers[mobilePlanIndex]
                                                                    .featured
                                                                    ? 'text-lime-600'
                                                                    : 'text-gray-900',
                                                                'text-sm font-medium',
                                                            )}
                                                        >
                                                            {feature.tiers[mobilePlanIndex].value}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {feature.tiers[mobilePlanIndex]
                                                                .value === true ? (
                                                                <CheckIcon
                                                                    className="mx-auto h-5 w-5 text-lime-600"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <XIcon
                                                                    className="mx-auto h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            )}

                                                            <span className="sr-only">
                                                                {feature.tiers[mobilePlanIndex]
                                                                    .value === true
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </span>
                                                        </>
                                                    )}
                                                </dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>

                                {/* Fake card border */}
                                <div
                                    aria-hidden="true"
                                    className="hidden absolute inset-0 pointer-events-none sm:block"
                                >
                                    <div
                                        className={classNames(
                                            plan.featured
                                                ? 'ring-2 ring-lime-600'
                                                : 'ring-1 ring-black ring-opacity-5',
                                            'absolute right-0 w-1/2 h-full rounded-lg',
                                        )}
                                    />
                                </div>
                            </div>

                            <h4 className="mt-10 text-sm font-bold text-gray-900">Other perks</h4>

                            <div className="mt-6 relative">
                                {/* Fake card background */}
                                <div
                                    aria-hidden="true"
                                    className="hidden absolute inset-0 pointer-events-none sm:block"
                                >
                                    <div
                                        className={classNames(
                                            plan.featured ? 'shadow-md' : 'shadow',
                                            'absolute right-0 w-1/2 h-full bg-white rounded-lg',
                                        )}
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        plan.featured
                                            ? 'ring-2 ring-lime-600 shadow-md'
                                            : 'ring-1 ring-black ring-opacity-5 shadow',
                                        'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none',
                                    )}
                                >
                                    <dl className="divide-y divide-gray-200">
                                        {perks.map((perk) => (
                                            <div
                                                key={perk.title}
                                                className="py-3 flex justify-between sm:grid sm:grid-cols-2"
                                            >
                                                <dt className="text-sm font-medium text-gray-600 sm:pr-4">
                                                    {perk.title}
                                                </dt>
                                                <dd className="text-center sm:px-4">
                                                    {perk.tiers[mobilePlanIndex].value === true ? (
                                                        <CheckIcon
                                                            className="mx-auto h-5 w-5 text-lime-600"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <XIcon
                                                            className="mx-auto h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    )}

                                                    <span className="sr-only">
                                                        {perk.tiers[mobilePlanIndex].value === true
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </span>
                                                </dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>

                                {/* Fake card border */}
                                <div
                                    aria-hidden="true"
                                    className="hidden absolute inset-0 pointer-events-none sm:block"
                                >
                                    <div
                                        className={classNames(
                                            plan.featured
                                                ? 'ring-2 ring-lime-600'
                                                : 'ring-1 ring-black ring-opacity-5',
                                            'absolute right-0 w-1/2 h-full rounded-lg',
                                        )}
                                    />
                                </div>
                            </div>

                            <h4 className="mt-10 text-sm font-bold text-gray-900">
                                Supported databases
                            </h4>

                            <div className="mt-6 relative">
                                {/* Fake card background */}
                                <div
                                    aria-hidden="true"
                                    className="hidden absolute inset-0 pointer-events-none sm:block"
                                >
                                    <div
                                        className={classNames(
                                            plan.featured ? 'shadow-md' : 'shadow',
                                            'absolute right-0 w-1/2 h-full bg-white rounded-lg',
                                        )}
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        plan.featured
                                            ? 'ring-2 ring-lime-600 shadow-md'
                                            : 'ring-1 ring-black ring-opacity-5 shadow',
                                        'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none',
                                    )}
                                >
                                    <dl className="divide-y divide-gray-200">
                                        {databases.map((perk) => (
                                            <div
                                                key={perk.title}
                                                className="py-3 flex justify-between sm:grid sm:grid-cols-2"
                                            >
                                                <dt className="text-sm font-medium text-gray-600 sm:pr-4">
                                                    {perk.title}
                                                </dt>
                                                <dd className="text-center sm:px-4">
                                                    {perk.tiers[mobilePlanIndex].value === true ? (
                                                        <CheckIcon
                                                            className="mx-auto h-5 w-5 text-lime-600"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <XIcon
                                                            className="mx-auto h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    )}

                                                    <span className="sr-only">
                                                        {perk.tiers[mobilePlanIndex].value === true
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </span>
                                                </dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>

                                {/* Fake card border */}
                                <div
                                    aria-hidden="true"
                                    className="hidden absolute inset-0 pointer-events-none sm:block"
                                >
                                    <div
                                        className={classNames(
                                            plan.featured
                                                ? 'ring-2 ring-lime-600'
                                                : 'ring-1 ring-black ring-opacity-5',
                                            'absolute right-0 w-1/2 h-full rounded-lg',
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Feature comparison (lg+) */}
            <section aria-labelledby="comparison-heading" className="hidden lg:block">
                <h2 id="comparison-heading" className="sr-only">
                    Feature comparison
                </h2>

                <div className="max-w-7xl mx-auto py-24 px-8">
                    <div className="w-full border-t border-gray-200 flex items-stretch">
                        <div className="-mt-px w-1/4 py-6 pr-4 flex items-end">
                            <h3 className="mt-auto text-sm font-bold text-gray-900">
                                Catered for business
                            </h3>
                        </div>
                        {plans.map((plan, planIdx) => (
                            <div
                                key={plan.title}
                                aria-hidden="true"
                                className={classNames(
                                    planIdx === plans.length - 1 ? '' : 'pr-4',
                                    '-mt-px pl-4 w-1/4',
                                )}
                            >
                                <div
                                    className={classNames(
                                        plan.featured ? 'border-lime-600' : 'border-transparent',
                                        'py-6 border-t-2',
                                    )}
                                >
                                    <p
                                        className={classNames(
                                            plan.featured ? 'text-lime-600' : 'text-gray-900',
                                            'text-sm font-bold',
                                        )}
                                    >
                                        {plan.title}
                                    </p>
                                    <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="relative">
                        {/* Fake card backgrounds */}
                        <div
                            className="absolute inset-0 flex items-stretch pointer-events-none"
                            aria-hidden="true"
                        >
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow-md" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                        </div>

                        <table className="relative w-full">
                            <caption className="sr-only">Business feature comparison</caption>
                            <thead>
                                <tr className="text-left">
                                    <th scope="col">
                                        <span className="sr-only">Feature</span>
                                    </th>
                                    {plans.map((plan) => (
                                        <th key={plan.title} scope="col">
                                            <span className="sr-only">{plan.title} plan</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                                {features.map((feature) => (
                                    <tr key={feature.title}>
                                        <th
                                            scope="row"
                                            className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600"
                                        >
                                            {feature.title}
                                        </th>
                                        {feature.tiers.map((tier, tierIdx) => (
                                            <td
                                                key={tier.title}
                                                className={classNames(
                                                    tierIdx === feature.tiers.length - 1
                                                        ? 'pl-4'
                                                        : 'px-4',
                                                    'relative w-1/4 py-0 text-center',
                                                )}
                                            >
                                                <span className="relative w-full h-full py-3">
                                                    {typeof tier.value === 'string' ? (
                                                        <span
                                                            className={classNames(
                                                                tier.featured
                                                                    ? 'text-lime-600'
                                                                    : 'text-gray-900',
                                                                'text-sm font-medium',
                                                            )}
                                                        >
                                                            {tier.value}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {tier.value === true ? (
                                                                <CheckIcon
                                                                    className="mx-auto h-5 w-5 text-lime-600"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <XIcon
                                                                    className="mx-auto h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            )}

                                                            <span className="sr-only">
                                                                {tier.value === true ? 'Yes' : 'No'}
                                                            </span>
                                                        </>
                                                    )}
                                                </span>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Fake card borders */}
                        <div
                            className="absolute inset-0 flex items-stretch pointer-events-none"
                            aria-hidden="true"
                        >
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-2 ring-lime-600 ring-opacity-100" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                        </div>
                    </div>

                    <h3 className="mt-10 text-sm font-bold text-gray-900">Other perks</h3>

                    <div className="mt-6 relative">
                        {/* Fake card backgrounds */}
                        <div
                            className="absolute inset-0 flex items-stretch pointer-events-none"
                            aria-hidden="true"
                        >
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow-md" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                        </div>

                        <table className="relative w-full">
                            <caption className="sr-only">Perk comparison</caption>
                            <thead>
                                <tr className="text-left">
                                    <th scope="col">
                                        <span className="sr-only">Perk</span>
                                    </th>
                                    {plans.map((plan) => (
                                        <th key={plan.title} scope="col">
                                            <span className="sr-only">{plan.title} plan</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                                {perks.map((perk) => (
                                    <tr key={perk.title}>
                                        <th
                                            scope="row"
                                            className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600"
                                        >
                                            {perk.title}
                                        </th>
                                        {perk.tiers.map((tier, tierIdx) => (
                                            <td
                                                key={tier.title}
                                                className={classNames(
                                                    tierIdx === perk.tiers.length - 1
                                                        ? 'pl-4'
                                                        : 'px-4',
                                                    'relative w-1/4 py-0 text-center',
                                                )}
                                            >
                                                <span className="relative w-full h-full py-3">
                                                    {tier.value === true ? (
                                                        <CheckIcon
                                                            className="mx-auto h-5 w-5 text-lime-600"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <XIcon
                                                            className="mx-auto h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    )}

                                                    <span className="sr-only">
                                                        {tier.value === true ? 'Yes' : 'No'}
                                                    </span>
                                                </span>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Fake card borders */}
                        <div
                            className="absolute inset-0 flex items-stretch pointer-events-none"
                            aria-hidden="true"
                        >
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-2 ring-lime-600 ring-opacity-100" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                        </div>
                    </div>

                    <h3 className="mt-10 text-sm font-bold text-gray-900">Supported databases</h3>

                    <div className="mt-6 relative">
                        {/* Fake card backgrounds */}
                        <div
                            className="absolute inset-0 flex items-stretch pointer-events-none"
                            aria-hidden="true"
                        >
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow-md" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                        </div>

                        <table className="relative w-full">
                            <caption className="sr-only">Supported databases comparison</caption>
                            <thead>
                                <tr className="text-left">
                                    <th scope="col">
                                        <span className="sr-only">Database</span>
                                    </th>
                                    {plans.map((plan) => (
                                        <th key={plan.title} scope="col">
                                            <span className="sr-only">{plan.title} plan</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                                {databases.map((perk) => (
                                    <tr key={perk.title}>
                                        <th
                                            scope="row"
                                            className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600"
                                        >
                                            {perk.title}
                                        </th>
                                        {perk.tiers.map((tier, tierIdx) => (
                                            <td
                                                key={tier.title}
                                                className={classNames(
                                                    tierIdx === perk.tiers.length - 1
                                                        ? 'pl-4'
                                                        : 'px-4',
                                                    'relative w-1/4 py-0 text-center',
                                                )}
                                            >
                                                <span className="relative w-full h-full py-3">
                                                    {tier.value === true ? (
                                                        <CheckIcon
                                                            className="mx-auto h-5 w-5 text-lime-600"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <XIcon
                                                            className="mx-auto h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    )}

                                                    <span className="sr-only">
                                                        {tier.value === true ? 'Yes' : 'No'}
                                                    </span>
                                                </span>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Fake card borders */}
                        <div
                            className="absolute inset-0 flex items-stretch pointer-events-none"
                            aria-hidden="true"
                        >
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-2 ring-lime-600 ring-opacity-100" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
