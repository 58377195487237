import React, { FC } from 'react';

import styled from 'styled-components';

type Props = {
    children: React.ReactNode;
};
export const CodeSnippet: FC<Props> = ({ children }) => {
    return (
        <CodeSnippetWrapper>
            <code className={'text-md lg:text-xl'}>➜</code>
            <code className={'text-md lg:text-xl'}>~</code>
            <code className={'text-md lg:text-xl'}>{children}</code>
        </CodeSnippetWrapper>
    );
};

const CodeSnippetWrapper = styled.div`
    margin: 15px 0 23px 0;
    display: inline-flex;
    align-self: start;
    background-color: #2f2f2f;
    border-width: 1px;
    border-style: solid;
    border-radius: 14px;
    padding: 11px 13px;
    code {
        font-family: monospace !important;
        color: white;
        margin-right: 10px;
        :first-child {
            user-select: none;
            color: #84ff00;
        }
        :nth-child(2) {
            user-select: none;
            color: #00ffef;
        }
    }
`;
