import styled from 'styled-components';
import { MarginProps, ColorProps, margin, color, FontSizeProps, fontSize } from 'styled-system';

type Props = MarginProps & ColorProps & FontSizeProps;
export const Heading = styled.p<Props>`
    font-family: ${(props) => props.theme.fonts.heading};
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.primary};
    font-weight: 700;
    ${margin}
    ${color}
    ${fontSize}
`;
