import React, { FC } from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';
import { flex, FlexProps, space, SpaceProps, variant } from 'styled-system';

import { Theme } from '../theme';
import { Row } from './Row';

type VariantProps = {
    variant?: 'regular' | 'danger' | 'outlined' | 'dark';
};

type Props = {
    children?: React.ReactNode | string;
    loading?: boolean;
    onClick?: () => void;
} & VariantProps &
    SpaceProps &
    FlexProps;

export const Button: FC<Props> = ({ loading, children, ...rest }) => {
    return (
        <Wrapper {...rest}>
            {loading ? (
                <ActivityIndicator
                    animate={{
                        rotate: 360,
                    }}
                    initial={{
                        rotate: 0,
                    }}
                    transition={{
                        flip: Infinity,
                        duration: 1,
                        ease: 'easeInOut',
                    }}
                />
            ) : (
                children
            )}
        </Wrapper>
    );
};
const Wrapper = styled(Row)<SpaceProps & VariantProps>`
    background-color: ${(props) => props.theme.colors.background.accent};
    padding: 15px 25px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    ${space}
    ${flex}
    ${variant({
        variants: {
            regular: {},
            danger: {
                backgroundColor: Theme.colors.background.danger,
            },
            outlined: {
                backgroundColor: 'white',
                border: '1px solid #101010',
            },
            dark: {
                backgroundColor: '#101010',
                color: 'white',
            },
        },
    })}
`;

const ActivityIndicator = styled(motion.div)`
    width: 15px;
    height: 15px;
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #383636; /* Black */
    border-radius: 50%;
`;
