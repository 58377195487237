import React from 'react';

import { CodeSnippet } from '../CodeSnippet';
import { View } from '../View';
export const Hero = () => {
    return (
        <div>
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-base font-semibold text-white tracking-wide uppercase">
                        AVAILABLE NOW
                    </h2>
                    <p className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
                        Run databases in cloud with ease
                    </p>
                    <p className="max-w-2xl mt-5 mx-auto text-2xl text-lime-400">
                        <strong className={'text-lime-100'}>Dead simple </strong>
                        database provisioning allows you to start using your cloud database in just{' '}
                        <strong className={'text-lime-100'}>30 seconds.</strong>
                    </p>
                    <View mt={20}>
                        <CodeSnippet>npm install -g @cloudstrukt/cli</CodeSnippet>
                    </View>
                </div>
            </div>
        </div>
    );
};
